import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React, { FC } from 'react';
import PageWindow from '../components/PageWindow';
import ShowDetails from '../components/ShowDetails';
import { ShowData, ShowInfo } from '../graphQL/showData';

const Show: FC<ShowData> = ({ data }) => {
    const { locale } = useIntl();
    const { genericShow, nlShow, enShow } = data.markdownRemark.frontmatter;
    const metaData =
        data.metadata.edges[0].node.childMarkdownRemark.frontmatter[
            `${locale}Meta`
        ]?.archiveShow;

    const nlOrEnShow = locale === 'nl' ? nlShow : enShow;
    const localizedDescription = nlOrEnShow.description;
    const localizedInfo = nlOrEnShow.info;
    if (nlOrEnShow?.meta_description) {
        metaData.description = nlOrEnShow.meta_description;
    }

    const {
        title,
        videos,
        photos,
        showBackgroundGradient,
        showBackgroundTriangle,
        higlighted_video,
        higlighted_photo,
        location,
        startDate,
        endDate,
        curator,
        facebook_url,
        facebook_url_display,
        website_url,
        website_url_display,
        article_url,
        article_url_display,
        sponsor,
    } = genericShow;

    const showInfo: ShowInfo = {
        title,
        location,
        startDate,
        endDate,
        curator,
        facebook_url,
        facebook_url_display,
        website_url,
        website_url_display,
        article_url,
        article_url_display,
        sponsor,
        info: localizedInfo,
    };

    const showDate = endDate ? new Date(endDate).getFullYear() : null;
    const windowTitle = `${showDate ? `${showDate}_` : ''}${title}`;

    return (
        <PageWindow
            windowTitle={windowTitle}
            description={localizedDescription}
            isShowWindow
            videos={videos}
            images={photos}
            showBackgroundGradient={showBackgroundGradient}
            showBackgroundTriangle={showBackgroundTriangle}
            highlightedVideo={higlighted_video}
            highlightedPhoto={higlighted_photo}
            extraInfo={<ShowDetails {...showInfo} />}
            metaData={metaData}
        />
    );
};

export default Show;

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            frontmatter {
                genericShow {
                    title
                    startDate
                    endDate
                    curator
                    facebook_url
                    facebook_url_display
                    website_url
                    website_url_display
                    article_url
                    article_url_display
                    sponsor
                    highlighted_photo {
                        photo_url
                        photo_alt
                        photo_title
                    }
                    photos {
                        photo_url
                        photo_alt
                        photo_title
                    }
                    highlighted_video {
                        video_url
                        video_title
                        video_slug
                    }
                    videos {
                        video_url
                        video_title
                        video_slug
                    }
                    showBackgroundGradient
                    showBackgroundTriangle
                    location
                }
                nlShow {
                    description
                    info
                    meta_description
                }
                enShow {
                    description
                    info
                    meta_description
                }
            }
            fields {
                slug
            }
        }
        metadata: allFile(filter: { absolutePath: { regex: "/meta_data/i" } }) {
            edges {
                node {
                    childMarkdownRemark {
                        frontmatter {
                            nlMeta {
                                archiveShow {
                                    description
                                    keywords {
                                        keyword
                                    }
                                }
                            }
                            enMeta {
                                archiveShow {
                                    description
                                    keywords {
                                        keyword
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
